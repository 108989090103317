:root {
  --primary-color: #1a237e;
  --secondary-color: #3f51b5;
  --tertiary-color: #5c6bc0;
  --quaternary-color: #9fa8da;
  --neutral-color: #c5cae9;
  --text-color: #ffffff;
  --text-color-alt: #000000;
}

body * {
  font-family: "Verdana", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

ul li {
  border: none !important;
}

.cls-1 {
  fill: var(--secondary-color);
}
.cls-2 {
  fill: var(--primary-color);
}
.cls-3 {
  fill: var(--primary-color);
}

#stripe {
  animation: stripe-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg);
  }
  75% {
    transform: translateX(10px);
  }
}
.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s;
}

.circle.c2 {
  animation-duration: 3s;
}

.circle.c3 {
  animation-duration: 1s;
}

.circle.c4 {
  animation-duration: 1s;
}

.circle.c5 {
  animation-duration: 2s;
}

.circle.c6 {
  animation-duration: 3s;
}

@keyframes circle-anim {
  50% {
    transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(0.98);
  }
}
.main{
  width: 100%;
  padding: 30px;
}
.diagonal-split-background {
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(
    30deg,
    var(--secondary-color) 50%,
    var(--primary-color) 50%
  );
}

.mm-color{
background-color: var(--primary-color);
color: #fff;
}
.chat-box {
  height: 75vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #999 #eee;
}

.chat-box::-webkit-scrollbar {
  width: 8px;
}

.chat-box::-webkit-scrollbar-track {
  background: #eee;
}

.chat-box::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  border: 3px solid #eee;
}

.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-messages li {
  padding: 10px;
}

.bot-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bot-messages li {
  padding: 10px;
}

.bounce {
  position: relative;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    top: 0;
  }
  50% {
    top: -20px;
  }
}

.container-auth {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.container-auth input[type="text"],
input[type="password"],
input[type="email"],
input[type="text"],
input[type="select"],
input[type="date"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.container-auth select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.container-auth button {
  background-color: var(--secondary-color);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.container-auth button:hover {
  background-color: var(--secondary-color);
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.input-box {
  border-radius: 20px;
  padding: 10px;
  background-color: #eee;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.settings-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.settings-button {
  font-size: 1.9rem;
  color: var(--secondary-color);
}

.input-box textarea {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
}

.submit-button {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--secondary-color);
}
.settings-modal {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.settings-modal label {
  display: block;
  margin-bottom: 10px;
}

.settings-modal button {
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.se-table-layout-fixed {
  table-layout: fixed;
}
table {
  border-collapse: collapse;
  width: 100%;
}

table tr:nth-child(even) {
  background-color: #c5cae95b;
}

table tr:nth-child(odd) {
  background-color: #99999975;
}

table th,
table td {
  padding: 10px;
  text-align: left;
}

table th,
table td {
  border: 1px solid #000000;
}

.my-color {
  background-color: var(--primary-color);
}

.my-shade {
  background-color: var(--secondary-color);
}

.my-color-text {
  color: var(--primary-color);
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.category-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  justify-items: center;
}

.category {
  width: 100%;
  max-width: 300px;
  min-height: 200px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.category-package {
  height: "200px";
  width: "100%"
}
.category-icon {
  width: 100%;
  height: 200px;
  background: url("../img/category.svg") no-repeat center center / cover;
}
.file {
  width: 100%;
  height: 200px;
  background: url("../img/policy.svg") no-repeat center center / cover;
}
.word {
  width: 100%;
  height: 200px;
  background: url("../img/word.svg") no-repeat center center / cover;
}
.pdf {
  width: 100%;
  height: 200px;
  background: url("../img/pdf.svg") no-repeat center center / cover;
}
.spreadsheet {
  width: 100%;
  height: 200px;
  background: url("../img/spreadsheet.svg") no-repeat center center / cover;
}

.parent-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }
  
  svg {
  fill: var(--primary-color);
  display: block;
  }

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-on-hover:hover {
  animation: pulse 0.5s ease-in-out;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination button {
  flex-grow: 1;
  background-color: #f1f1f1;
  color: #555;
  border: 1px solid #ddd;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button.active {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
}

.pagination button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.category-name {
  padding: 10px;
}

.modules {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.module {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: #f4f4f4;
  font-weight: bold;
  flex-direction: column;
}

.icon-container {
  margin-bottom: 10px;
}

.title-container {
  text-align: center;
}

.flex-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  background-color: var(--secondary-color);
}

.flex-card:hover {
  background-color: var(--primary-color);
}
.flex-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex-card-title {
  margin-left: 20px;
  right: 10px;
  position: relative;
}

.skills-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skill {
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  flex: 1 0 200px;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.feature {
  background-color: #eeeeee30;
  padding: 20px;
  color: #385875;
  border-radius: 5px;
  text-align: center;
}

.feature:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.feature h4 {
  margin: 10px 0;
  color: #aaa;
}

.feature:hover h4 {
  margin: 10px 0;
  color: #fff;
}

.cta-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-me .contact-info {
  margin-top: 20px;
}

.contact-me .contact-info p {
  margin-bottom: 10px;
}

.contact-me .contact-form {
  margin-top: 20px;
}

.contact-me .contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-me .contact-form label {
  margin-bottom: 5px;
}

.contact-me .contact-form input,
.contact-me .contact-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.contact-me .contact-form input[type="submit"] {
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
}

.cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30vw;
  min-width: 300px;
  max-height: 80vh;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  margin: 0;
}

.cart-header {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--secondary-color);
  color: #fff;
  border-bottom: none;
  cursor: move;
}

.cart-header h4 {
  margin: 0;
}

.cart-header button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.cart-body-container {
  height: calc(80vh - 50px);
  overflow-y: auto;
}
.cart-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cart-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.cart-item-name {
  flex: 1;
}

.cart-item-price,
.cart-item-quantity,
.cart-item-total {
  flex: 1;
  text-align: right;
}

.cart-total {
  padding: 10px;
  font-weight: bold;
}

.cart-checkout-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  cursor: pointer;
}

.logout-span {
   align-self: flex-end;
   width: 100%;
    background-color: var(--primary-color);
    color: #ffffff;
    cursor: pointer;
  
  }

  .logo-active-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }

    .sidenav {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
    }
    
    .sidenav a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-bottom: 20px;
      transition: color 0.3s ease;
    }
    
    .sidenav a:hover {
      color: var(--primary-color);
    }
    
    .sidenav a span {
      margin-left: 10px;
    }


    .in-sidenav {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #fff;
      padding: 20px;
    }
    
    .in-sidenav a,section {
      display: flex;
      align-items: center;
      color: #333333;
      text-decoration: none;
      margin-bottom: 20px;
      transition: color 0.3s ease;
    }
    
    .in-sidenav a,section:hover {
      color: var(--primary-color);
      cursor: pointer;
    }
    
    .in-sidenav a span {
      margin-left: 10px;
    }

    .sidenav section span {
      margin-left: 10px;
    }
    
    .menu-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      }

      .date-picker {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }
      
      .date-label {
        position: absolute;
        top: -20px;
        left: 10px;
        background: #fff;
        padding: 0 5px;
        font-size: 14px;
        color: #888;
      }
 
      .chat-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto;
    }
    
    .chat-input-container {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
    }
    
    .chat-input {
        width: 100%;
        background: #eee;
        border: none;
        height: 100px;
        padding: 10px;
        border-radius: 5px;
        resize: none;
        outline: none;
    }
    
    .file-input-container {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        
    }
    
    #file-input {
        display: none;
    }
    
    .file-input-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border: none;
        color: rgb(255, 255, 255);
        background-color: rgb(129, 127, 127);
        border-radius: 5px;
        cursor: pointer;
        margin-left: 10px;
    }
    
    .input-icon {
        width: 20px;
        height: 20px;
    }
    
    .submit-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: white;
        cursor: pointer;
    }

    .submit-button:hover {
      background-color: white;
      color: black;
  }

    .file-preview {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f8f8f8;
  }
  
  .file-icon {
      margin-right: 10px;
  }
  
  .file-info p {
      margin: 0;
  }
  
  .remove-file-button {
      border: none;
      background: none;
      cursor: pointer;
      color: #ff0000;
  }

  .mm-label-create {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .mm-input-create {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .or-separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #000;
    margin: 20px 0;
  }
  
  .or-separator .line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
  }
  
  .or-separator span {
    padding: 0 10px;
  }

  .form-line:focus{
    outline: none;
    box-shadow: none;
    border: solid 1px #555 ;
  }

  .chat-box {
    width: 350px;
    height: 550px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-messages {
    overflow-y: auto;
    flex-grow: 1;
  }
  
  @media screen and (max-width: 400px) {
    .chat-box {
      width: 100%;
      height: 100%;
    }
  }
  
  .no-outline {
    outline: none;
    border: solid 1px #999;
    box-shadow: none;
  }
  .no-outline:focus {
    outline: none;
    border: solid 1px #999;
    box-shadow: none;
  }
  
  .no-outline-bot {
    outline: none;
    border: none;
    box-shadow: none;
  }
  .no-outline-bot:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  .no-resize {
    resize: none;
  }
  
  .chat-messages {
    background-image: url('../img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .message {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message p {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .message.user p {
    background-color: var(--primary-color);
    color: white;
  }
  
  .PhoneInputInput{
    border: none;
    outline: none;
  }
  
  .PhoneInputInput:focus{
    border: none;
    outline: none;
  }
  .form-line:focus{
    outline: none;
    box-shadow: none;
    border: solid 1px #ddd ;
  }
  
  .mm-color-outline{
    border: solid 1px var(--primary-color);
    color: var(--primary-color);
  }

.task-form { 
    display: flex; 
    flex-wrap: wrap; 
    gap: 10px; 
    margin-bottom: 20px; 
} 
  
.task-form input, 
.task-form select, 
.task-form button { 
    padding: 10px; 
    border: 1px solid #ccc; 
    font-size: 12px; 
    flex: 1; 
    border-radius: 10px; 
} 
  
.mark-done { 
    padding: 10px; 
    font-size: 12px; 
    flex: 1; 
    border-radius: 15px; 
    background-color: crimson; 
    color: white; 
    border: none; 
    cursor: pointer; 
} 
  
.completed-task-list { 
    margin-top: 20px; 
} 
  
.completed-task { 
    padding: 10px; 
    border: 1px solid crimson; 
    border-radius: 5px; 
    background-color: #eaffea; 
} 
  
.completed-task h6 { 
    color: var(--primary-color); 
} 
  
.heading { 
    padding-bottom: 10px; 
} 
  
.cheading { 
    color: rgb(54, 54, 54); 
}

.offcanvas {
  width: 100%;
}

@media (min-width: 768px) {
  .offcanvas {
    width: 50VW;
  }
}

.react-calendar__tile--active {
  background-color: var(--primary-color) !important;
}

.no-border {
  border: none !important;
}

.no-border th, .no-border td {
  border: none !important;
}

.mm-pagination .active .page-link {
  background-color: var(--secondary-color)!important;
  border-color: var(--secondary-color) !important;
}

.tox-edit-area__iframe {
  height: 75vh !important;
}